import { CheckCircle } from 'iconoir-react';
import React, { ReactElement } from 'react';
import { useMedia } from 'react-use';
import { useTheme } from 'styled-components';

import { media } from '@savgroup-front-common/constants';
import { Checkbox } from '@savgroup-front-common/core/src/atoms/checkbox';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { MessageType } from '@savgroup-front-common/types';
import { PostLabelTooltip } from 'authenticator/view/atoms';

import {
  $ActivitiesCardSelectorContainer,
  $ActivityCard,
  $ActivityCardWithoutIcon,
  $CheckIconPosition,
  $Label,
  $LabelWithCheckbox,
  $LabelWithoutIcon,
} from './ActivitiesCardSelector.styles';

export interface Activity {
  value: string;
  label: MessageType;
  icon?: ReactElement;
  description?: MessageType;
}

const ActivitiesCardSelector = ({
  activities,
  rowSize,
  handleSelectedActivities,
  selectedActivities,
  name,
}: {
  activities: Activity[];
  rowSize: number;
  handleSelectedActivities: (domains: string, name?: string) => void;
  selectedActivities: string[];
  name?: string;
}) => {
  const theme = useTheme();
  const isClassicView = useMedia(media.maxWidth.lg);
  const isMobileView = useMedia(media.maxWidth.sm);

  return (
    <$ActivitiesCardSelectorContainer
      $rowSize={isMobileView ? 2 : rowSize}
      $activities={activities}
    >
      {activities.map((activity) => {
        if (activity.icon) {
          return (
            <$ActivityCard
              key={activity.value}
              onClick={() => handleSelectedActivities(activity.value, name)}
              $isActive={selectedActivities.includes(activity.value)}
            >
              {selectedActivities.includes(activity.value) && (
                <$CheckIconPosition>
                  <CheckCircle color={theme.newUI.variationColors?.dark2} />
                </$CheckIconPosition>
              )}

              {activity.icon}
              <$Label>
                <SafeFormattedMessageWithoutSpread message={activity.label} />
              </$Label>
            </$ActivityCard>
          );
        }

        return (
          <$ActivityCardWithoutIcon
            key={activity.value}
            onClick={() => handleSelectedActivities(activity.value, name)}
            $isActive={selectedActivities.includes(activity.value)}
          >
            <$LabelWithCheckbox>
              <Checkbox
                name="checkbox"
                isNewUi
                checked={selectedActivities.includes(activity.value)}
                withoutMargin={!isClassicView}
              />
              <$LabelWithoutIcon>
                <SafeFormattedMessageWithoutSpread message={activity.label} />
              </$LabelWithoutIcon>
              {activity.description && (
                <PostLabelTooltip isNewUi>
                  <SafeFormattedMessageWithoutSpread
                    message={activity.description}
                  />
                </PostLabelTooltip>
              )}
            </$LabelWithCheckbox>
          </$ActivityCardWithoutIcon>
        );
      })}
    </$ActivitiesCardSelectorContainer>
  );
};

ActivitiesCardSelector.displayName = 'ActivitiesCardSelector';

export default ActivitiesCardSelector;
