import React from 'react';
import { Controller, FormProvider } from 'react-hook-form';
import { Col, Grid } from 'react-styled-flexboxgrid';
import { useTheme } from 'styled-components';

import {
  FormGroup,
  RadioButtonGroup,
} from '@savgroup-front-common/core/src/atoms/Form';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { RadioCardColumn } from '@savgroup-front-common/core/src/molecules/RadioCardColumn';
import { CUSTOMER_TYPE } from '@savgroup-front-common/types';
import {
  CreateClaimContext,
  CreateClaimValues,
  ORDER_LOGIN_CONFIGURATION,
} from 'authenticator/types';
import StepLayout from 'authenticator/view/app/layout/NewDesignLayout/StepLayout/StepLayout';
import STEP_CONFIG from 'authenticator/view/app/Routes/StepConfig';

import HasInvoiceIcons from '../../../../atoms/Icons/HasInvoiceIcons';
import HasNotInvoiceIcons from '../../../../atoms/Icons/HasNotInvoiceIcons';
import { getColumn } from '../../helpers';
import messages from '../../messages';
import { $RowContainer } from '../../MyAccountLoginPageNewDesign.styles';

import useChoiceInvoice from './ChoiceInvoice.hooks';
import {
  $HasNotInvoiceIconsContainer,
  $InvoiceSvgItem,
  $InvoiceSvgLogo,
} from './ChoiceInvoice.styles';
import { CHOICE_INVOICE } from './ChoiceInvoice.types';
import InvoiceLogo from './components/InvoiceLogo';

interface ChoiceInvoiceProps {
  context: CreateClaimContext;
  values: CreateClaimValues;
}

const ChoiceInvoice: React.FC<ChoiceInvoiceProps> = ({ values }) => {
  const {
    formContext,
    onSubmit,
    hasPartner,
    enableGenericProduct,
    sellerId,
    activatedSellerPartners,
    sellerName,
    orderLoginConfiguration,
    hasOneInputOnPage,
  } = useChoiceInvoice({ values });
  const theme = useTheme();

  const {
    control,
    formState: { isValid },
  } = formContext;

  const isGenericProduct = enableGenericProduct ? 1 : 0;
  const hasOrderLoginConfiguration =
    orderLoginConfiguration !== ORDER_LOGIN_CONFIGURATION.HIDDEN ? 1 : 0;
  const caseLength =
    activatedSellerPartners.length +
    isGenericProduct +
    hasOrderLoginConfiguration;

  if (hasOneInputOnPage) {
    return <></>;
  }

  return (
    <FormProvider {...formContext}>
      <form
        onSubmit={(event) => {
          event.preventDefault();

          return undefined;
        }}
      >
        <StepLayout
          values={values}
          stepName={STEP_CONFIG.CHOICE_INVOICE}
          isDisabled={!isValid}
          noConfirmButton
        >
          <FormGroup>
            <Controller
              control={control}
              name="choiceInvoice"
              render={({ field }) => {
                return (
                  <RadioButtonGroup name="choiceInvoiceRadioButtonGroup">
                    <Grid fluid>
                      <$RowContainer>
                        {orderLoginConfiguration !==
                          ORDER_LOGIN_CONFIGURATION.HIDDEN && (
                          <Col xs={12} sm={12} md={getColumn(caseLength)}>
                            <RadioCardColumn
                              title={
                                <SafeFormattedMessageWithoutSpread
                                  message={
                                    messages.choiceInvoiceButtonHasInvoice
                                  }
                                  values={{
                                    sellerName,
                                  }}
                                />
                              }
                              name={field.name}
                              value={CHOICE_INVOICE.HAS_INVOICE}
                              isChecked={
                                field.value === CHOICE_INVOICE.HAS_INVOICE
                              }
                              onChange={field.onChange}
                              onClick={() =>
                                onSubmit({
                                  choiceInvoice: CHOICE_INVOICE.HAS_INVOICE,
                                })
                              }
                              image={
                                <$InvoiceSvgItem>
                                  <HasInvoiceIcons />
                                  <$InvoiceSvgLogo>
                                    <InvoiceLogo sellerId={sellerId} />
                                  </$InvoiceSvgLogo>
                                </$InvoiceSvgItem>
                              }
                            />
                          </Col>
                        )}

                        {hasPartner &&
                          activatedSellerPartners.map((partner) => {
                            return (
                              <Col
                                key={partner.sellerName}
                                xs={12}
                                sm={12}
                                md={getColumn(caseLength)}
                              >
                                <RadioCardColumn
                                  title={
                                    <SafeFormattedMessageWithoutSpread
                                      message={
                                        messages.choiceInvoiceButtonHasPartner
                                      }
                                      values={{
                                        sellerName: partner.sellerName,
                                      }}
                                    />
                                  }
                                  name={partner.sellerName}
                                  value={partner.sellerId}
                                  isChecked={Boolean(
                                    values.activatedSellerPartnersIdSelected
                                      ?.sellerId &&
                                      values.activatedSellerPartnersIdSelected
                                        ?.sellerId === partner.sellerId &&
                                      values.activatedSellerPartnersIdSelected
                                        .customerType === partner.customerType,
                                  )}
                                  onChange={field.onChange}
                                  onClick={() =>
                                    onSubmit({
                                      choiceInvoice: {
                                        sellerId: partner.sellerId,
                                        customerType:
                                          partner.customerType ||
                                          CUSTOMER_TYPE.NOT_SET,
                                      },
                                    })
                                  }
                                  image={
                                    <$InvoiceSvgItem>
                                      <HasInvoiceIcons />
                                      <$InvoiceSvgLogo>
                                        <InvoiceLogo
                                          sellerId={partner.sellerId}
                                        />
                                      </$InvoiceSvgLogo>
                                    </$InvoiceSvgItem>
                                  }
                                />
                              </Col>
                            );
                          })}

                        {enableGenericProduct && (
                          <Col xs={12} sm={12} md={getColumn(caseLength)}>
                            <RadioCardColumn
                              title={messages.choiceInvoiceButtonHasNotInvoice}
                              name={field.name}
                              value={CHOICE_INVOICE.HAS_NOT_INVOICE}
                              isChecked={
                                field.value === CHOICE_INVOICE.HAS_NOT_INVOICE
                              }
                              onChange={field.onChange}
                              onClick={() =>
                                onSubmit({
                                  choiceInvoice: CHOICE_INVOICE.HAS_NOT_INVOICE,
                                })
                              }
                              image={
                                <$HasNotInvoiceIconsContainer>
                                  <HasNotInvoiceIcons
                                    color={
                                      field.value ===
                                      CHOICE_INVOICE.HAS_NOT_INVOICE
                                        ? theme.colors.primary
                                        : theme.colors.paragraphTextColor
                                    }
                                  />
                                </$HasNotInvoiceIconsContainer>
                              }
                            />
                          </Col>
                        )}
                      </$RowContainer>
                    </Grid>
                  </RadioButtonGroup>
                );
              }}
            />
          </FormGroup>
        </StepLayout>
      </form>
    </FormProvider>
  );
};

ChoiceInvoice.displayName = 'ChoiceInvoice';

export default ChoiceInvoice;
