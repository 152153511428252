import { IconoirProvider } from 'iconoir-react';
import React, { useCallback } from 'react';
import { useTheme } from 'styled-components';

import { LANGUAGES } from '@savgroup-front-common/constants';
import WizardNewUi from '@savgroup-front-common/core/src/atoms/wizard/customWizard/WizardNewUi/WizardNewUi';
import { LanguageChooser } from '@savgroup-front-common/core/src/molecules/LanguageChooser';
import { StepsOrchestrator } from '@savgroup-front-common/core/src/molecules/StepsOrchestrator';
import { ReversioIcon } from '@savgroup-front-common/core/src/protons/icons';

import {
  $HeaderContainer,
  $MenuContainer,
  $PageWrapper,
} from '../OnboardingSetUp/OnboardingSetUp.styles';

import useSavePersonalizedDemoContextCommand from './hooks/useSavePersonalizedDemoContextCommand';
import {
  ACTIVITIES_STEPS,
  ACTIVITIES_STEPS_NAMES,
} from './OnboardingActivities.steps';
import { $ActivitiesContainer } from './OnboardingActivities.styles';
import { OnboardingActivitiesSubmitValues } from './OnboardingActivities.types';

const OnboardingActivities: React.FC = () => {
  const theme = useTheme();
  const { handleSubmitSalesLead, isLoading } =
    useSavePersonalizedDemoContextCommand();

  const handleSubmit = useCallback(
    (value: OnboardingActivitiesSubmitValues) => {
      handleSubmitSalesLead(value);
    },
    [handleSubmitSalesLead],
  );

  return (
    <$PageWrapper>
      <$HeaderContainer>
        <$MenuContainer>
          <a href="https://www.revers.io">
            <ReversioIcon color={theme.newUI.defaultColors.menu} />
          </a>
          <LanguageChooser acceptedLanguages={[LANGUAGES.FR, LANGUAGES.EN]} />
        </$MenuContainer>
      </$HeaderContainer>
      <$ActivitiesContainer>
        <IconoirProvider
          iconProps={{
            width: 24,
            height: 24,
            color: theme.newUI.variationColors?.dark1,
          }}
        >
          <StepsOrchestrator<OnboardingActivitiesSubmitValues>
            initialStep={ACTIVITIES_STEPS_NAMES.CUSTOMER_VERTICAL_PAGE}
            config={ACTIVITIES_STEPS}
            onSubmit={handleSubmit}
            CustomWizard={WizardNewUi}
            valuesFromParent={{ isLoading }}
          />
        </IconoirProvider>
      </$ActivitiesContainer>
    </$PageWrapper>
  );
};

OnboardingActivities.displayName = 'OnboardingActivities';

export default OnboardingActivities;
