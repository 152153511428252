import React from 'react';

import ClientInformationContext from './ClientInformationContext';
import { useMyAccountLoginPage } from './hooks';

const ClientInformationProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { clientInformation, sellers, isLoading } = useMyAccountLoginPage();

  if (!clientInformation) {
    return <>{children}</>;
  }

  return (
    <ClientInformationContext.Provider
      value={{ clientInformation, sellers, isLoading }}
    >
      {children}
    </ClientInformationContext.Provider>
  );
};

export default ClientInformationProvider;
