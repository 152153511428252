import React, { Fragment, FunctionComponent } from 'react';
import { useMedia } from 'react-use';

import { media } from '@savgroup-front-common/constants';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { CheckIcon } from '@savgroup-front-common/core/src/protons/icons';
import STEP_CONFIG from 'authenticator/view/app/Routes/StepConfig';

import { getLabel } from './StepProgress.helpers';
import {
  $StepProgressCircle,
  $StepProgressItem,
  $StepProgressLabel,
  $StepProgressSeparator,
  $StepProgressWrapper,
} from './StepProgress.styles';

const StepProgress: FunctionComponent<{ stepName?: STEP_CONFIG }> = ({
  stepName,
}) => {
  const isMobileView = useMedia(media.maxWidth.xs);
  const steps: Record<string, (STEP_CONFIG | undefined)[]>[] = [
    {
      informations: [
        STEP_CONFIG.CHOICE_INVOICE,
        STEP_CONFIG.CHOICE_PARTNER_STORE,
        STEP_CONFIG.CHOICE_PARTNER_PRODUCT,
        STEP_CONFIG.CHOICE_UNIVERS_MODEL,
        STEP_CONFIG.CHOICE_MODEL_GENERIC,
        STEP_CONFIG.CUSTOMER_INFORMATION_NAME,
        STEP_CONFIG.CUSTOMER_INFORMATION_ADDRESS,
        STEP_CONFIG.CUSTOMER_INVOICE_INFORMATION,
      ],
    },
    {
      requests: [],
    },
    {
      solutions: [],
    },
    {
      confirmation: [],
    },
  ];

  const currentStepFound = steps.find((stepValues) =>
    Object.entries(stepValues).some(([, values]) =>
      values.some((value) => value === stepName),
    ),
  );

  let hasChecked = false;

  const stateSteps = steps.map((step) => {
    const label = Object.keys(step)[0];
    const values = Object.values(step)[0];
    const isCurrent = values?.some((value) => value === stepName) || false;

    if (currentStepFound && isCurrent) {
      hasChecked = true;

      return { label, isCurrent: true, isChecked: false };
    }

    return { label, isCurrent: false, isChecked: !hasChecked };
  });

  return (
    <$StepProgressWrapper>
      {stateSteps.map((step, index) => (
        <Fragment key={step.label}>
          <$StepProgressItem>
            <$StepProgressCircle $active={step.isChecked || step.isCurrent}>
              {step.isChecked && <CheckIcon color="#fff" size="14px" />}
              {!step.isChecked && index + 1}
            </$StepProgressCircle>
            {!isMobileView && (
              <$StepProgressLabel>
                <SafeFormattedMessageWithoutSpread
                  message={getLabel(step.label)}
                />
              </$StepProgressLabel>
            )}
          </$StepProgressItem>
          <$StepProgressSeparator $active={step.isChecked} />
        </Fragment>
      ))}
    </$StepProgressWrapper>
  );
};

export default StepProgress;
