import React from 'react';
import { Controller } from 'react-hook-form';
import { Col } from 'react-styled-flexboxgrid';
import { useTheme } from 'styled-components';

import {
  BUTTON_TYPES,
  NOTIFICATION_TYPES,
} from '@savgroup-front-common/constants';
import { Banner } from '@savgroup-front-common/core/src/atoms/Banners';
import { FieldMessage } from '@savgroup-front-common/core/src/atoms/Form/common';
import { FIELD_STATUS } from '@savgroup-front-common/core/src/atoms/Form/common/helpers/getFinalFieldState.types';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { InputHookForm } from '@savgroup-front-common/core/src/molecules/Form';
import { InformationIcon } from '@savgroup-front-common/core/src/protons/icons';

import AgreementCheckbox from './AgreementCheckbox/AgreementCheckbox';
import useOnboardingFormContext from './hooks/useOnboardingFormContext';
import messages from './messages';
import {
  $BannerMessage1,
  $Bold,
  $DomainName,
  $Form,
  $InputRow,
  $MainDomainName,
  $Preview,
  $Row,
  $SubmitButton,
} from './OnboardingForm.styles';

const OnboardingForm = ({
  handleUserEmail,
}: {
  handleUserEmail: (userEmail: string) => void;
}) => {
  const theme = useTheme();
  const {
    handleFormSubmit,
    formContext,
    isLoading,
    domain,
    isDomainNameLoading,
  } = useOnboardingFormContext({ handleUserEmail });
  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
    watch,
  } = formContext;

  const hasCName = watch('cName');
  const domainNameSplitted = domain?.domainName.split('.');
  const mainDomainName = domainNameSplitted?.[0];
  const residualDomainName = domainNameSplitted?.slice(1).join('.');

  return (
    <$Form onSubmit={handleSubmit(handleFormSubmit)}>
      <$Row>
        <Col xs={12} md={6}>
          <InputHookForm
            name="lastName"
            errors={errors}
            label={messages.lastName}
            register={register}
            isRequired
          />
        </Col>
        <Col xs={12} md={6}>
          <InputHookForm
            name="firstName"
            errors={errors}
            label={messages.firstName}
            register={register}
            isRequired
          />
        </Col>
      </$Row>
      <$InputRow>
        <InputHookForm
          name="companyName"
          errors={errors}
          label={messages.companyName}
          isRequired
          register={register}
        />
      </$InputRow>
      <$InputRow>
        <InputHookForm
          name="cName"
          errors={errors}
          label={messages.cName}
          isRequired
          register={register}
        />
      </$InputRow>
      {hasCName && (
        <$Preview>
          <SafeFormattedMessageWithoutSpread
            message={messages.preview}
            values={{
              domainName: mainDomainName && (
                <$DomainName>
                  <$MainDomainName>{mainDomainName}</$MainDomainName>
                  {`.${residualDomainName}`}
                </$DomainName>
              ),
            }}
          />
        </$Preview>
      )}
      <Banner
        notificationType={NOTIFICATION_TYPES.DEFAULT}
        isOpen
        isNewUi
        isFluid
        icon={
          <InformationIcon
            color={theme.newUI.defaultColors.primary}
            size="24px"
          />
        }
      >
        <div>
          <$Bold>
            <SafeFormattedMessageWithoutSpread
              message={messages.bannerMessageBold1}
            />
          </$Bold>
          &nbsp;
          <$BannerMessage1>
            <SafeFormattedMessageWithoutSpread
              message={messages.bannerMessage1}
            />
          </$BannerMessage1>
        </div>
        <div>
          <SafeFormattedMessageWithoutSpread
            message={messages.bannerMessage2}
          />
        </div>
      </Banner>
      <$InputRow>
        <InputHookForm
          name="email"
          errors={errors}
          label={messages.email}
          register={register}
          isRequired
        />
      </$InputRow>
      <$InputRow>
        <InputHookForm
          name="phone"
          errors={errors}
          label={messages.phone}
          register={register}
          isRequired
        />
      </$InputRow>
      <$InputRow>
        <Controller
          control={control}
          name="cgv"
          render={({ field }) => {
            return (
              <AgreementCheckbox
                isChecked={field.value}
                onChange={field.onChange}
                isError={!!errors.cgv?.message}
                isNewUi
              />
            );
          }}
        />
        <FieldMessage
          message={errors.cgv?.message}
          status={FIELD_STATUS.ERROR}
        />
      </$InputRow>
      <$SubmitButton
        type={BUTTON_TYPES.SUBMIT}
        fluid
        primary
        isNewUi
        isLoading={isLoading || isDomainNameLoading}
        isError={!!Object.keys(errors).length}
      >
        <SafeFormattedMessageWithoutSpread message={messages.trialStart} />
      </$SubmitButton>
    </$Form>
  );
};

OnboardingForm.displayName = 'OnboardingForm';

export default OnboardingForm;
