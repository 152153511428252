import { defineMessages } from 'react-intl';

export default defineMessages({
  returnVolumeTitle: {
    id: 'authenticator.onboarding.returns.returnVolumeTitle',
    defaultMessage: 'What is your return volume by year ?',
  },
  returnMethodsTitle: {
    id: 'authenticator.onboarding.returns.returnMethodsTitle',
    defaultMessage: 'What are your return methods for your clients ?',
  },
  returnMethodsIssueTitle: {
    id: 'authenticator.onboarding.returns.returnMethodsIssueTitle',
    defaultMessage:
      'What are the issues you encounter with your return methods ?',
  },
  next: {
    id: 'authenticator.onboarding.button.finish',
    defaultMessage: 'Finish',
  },
  previous: {
    id: 'authenticator.onboarding.button.goBack',
    defaultMessage: 'Back to previous step',
  },
  store: {
    id: 'authenticator.onboarding.returns.store',
    defaultMessage: 'In store',
  },
  pickupPoint: {
    id: 'authenticator.onboarding.returns.pickupPoint',
    defaultMessage: 'In a pickup point',
  },
  homePickup: {
    id: 'authenticator.onboarding.returns.homePickup',
    defaultMessage: 'Home pickup',
  },
  client: {
    id: 'authenticator.onboarding.returns.client',
    defaultMessage: 'On client fee',
  },
  minReturnVolume: {
    id: 'authenticator.onboarding.returns.minReturnVolume',
    defaultMessage: '0 - 1 000',
  },
  littleReturnVolume: {
    id: 'authenticator.onboarding.returns.littleReturnVolume',
    defaultMessage: '1 000 - 5 000',
  },
  mediumReturnVolume: {
    id: 'authenticator.onboarding.returns.mediumReturnVolume',
    defaultMessage: '5 000 - 10 000',
  },
  highReturnVolume: {
    id: 'authenticator.onboarding.returns.highReturnVolume',
    defaultMessage: '10 000 - 100 000',
  },
  maxReturnVolume: {
    id: 'authenticator.onboarding.returns.maxReturnVolume',
    defaultMessage: '+ of 100 000',
  },
  retractation: {
    id: 'authenticator.onboarding.returns.retractation',
    defaultMessage: 'Retractation',
  },
  sav: {
    id: 'authenticator.onboarding.returns.sav',
    defaultMessage: 'After sales service',
  },
  deliveryIncident: {
    id: 'authenticator.onboarding.returns.deliveryIncident',
    defaultMessage: 'Delivery incident',
  },
  deliveryIncidentDescription: {
    id: 'authenticator.onboarding.returns.deliveryIncidentDescription',
    defaultMessage: 'Broken, damaged, lost, etc.',
  },
  customersTitle: {
    id: 'authenticator.onboarding.returns.customersTitle',
    defaultMessage: 'What are your customers ?',
  },
  enterprises: {
    id: 'authenticator.onboarding.returns.enterprises',
    defaultMessage: 'Enterprises',
  },
  private: {
    id: 'authenticator.onboarding.returns.private',
    defaultMessage: 'Private',
  },
  both: {
    id: 'authenticator.onboarding.returns.both',
    defaultMessage: 'Both',
  },
});
