import { Antenna, Cart, Garage, Packages, Shop, Tools } from 'iconoir-react';
import React from 'react';

import { OnboardingCustomerVertical } from '../../OnboardingActivities.constant';

import messages from './messages';

const useCustomerVerticalPage = () => {
  return [
    {
      value: OnboardingCustomerVertical.RETAILER,
      label: messages.retailer,
      icon: <Shop />,
    },
    {
      value: OnboardingCustomerVertical.E_COMMERCE,
      label: messages.eCommercer,
      icon: <Cart />,
    },
    {
      value: OnboardingCustomerVertical.REPAIRER,
      label: messages.repairer,
      icon: <Tools />,
    },
    {
      value: OnboardingCustomerVertical.MANUFACTURER,
      label: messages.manufacturer,
      icon: <Packages />,
    },
    {
      value: OnboardingCustomerVertical.INDUSTRIAL,
      label: messages.industrial,
      icon: <Antenna />,
    },
    {
      value: OnboardingCustomerVertical.WHOLESALER,
      label: messages.wholesaler,
      icon: <Garage />,
    },
  ];
};

export default useCustomerVerticalPage;
