import React, { MouseEvent } from 'react';
import { Col } from 'react-styled-flexboxgrid';
import { useTheme } from 'styled-components';

import { RadioCardColumn } from '@savgroup-front-common/core/src/molecules/RadioCardColumn';
import { MyAccountIcon } from '@savgroup-front-common/core/src/protons/IconsNewDesign/MyAccount.icon';
import { UniverseModelType } from 'authenticator/types';

import { $IconSvgItem } from '../../MyAccountLoginPageNewDesign.styles';

interface ChoiceProductUniversModelProps {
  modelTypeUniverse: UniverseModelType;
  isChecked: boolean;
  onChange: () => void;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  column: number;
}

const ChoiceProductUniverseModelCard: React.FC<
  ChoiceProductUniversModelProps
> = ({
  modelTypeUniverse,
  isChecked,
  onChange,
  onClick = () => undefined,
  column,
}) => {
  const theme = useTheme();

  return (
    <Col xs={12} sm={6} md={column}>
      <RadioCardColumn
        title={modelTypeUniverse.name}
        name={modelTypeUniverse.name}
        value={modelTypeUniverse.id}
        isChecked={isChecked}
        onChange={onChange}
        onClick={onClick}
        image={
          <$IconSvgItem>
            <MyAccountIcon
              icon={modelTypeUniverse.iconKey}
              color={
                isChecked
                  ? theme.colors.primary
                  : theme.colors.paragraphTextColor
              }
            />
          </$IconSvgItem>
        }
      />
    </Col>
  );
};

export default ChoiceProductUniverseModelCard;
