import * as Yup from 'yup';

import { globalMessages } from '@savgroup-front-common/core/src/helpers';

const EmailOrderReferenceSchema = Yup.object().shape({
  orderReference: Yup.string().required(globalMessages.form.required),
  email: Yup.string()
    .email(globalMessages.form.invalid)
    .required(globalMessages.form.required),
});

export default EmailOrderReferenceSchema;
