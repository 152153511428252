import { defineMessages } from 'react-intl';

export default defineMessages({
  domainsWizard: {
    id: 'authenticator.onboarding.activities.wizard.domains',
    defaultMessage: 'Your domains',
  },
  customerVerticalWizard: {
    id: 'authenticator.onboarding.activities.wizard.customerVertical',
    defaultMessage: 'Your customer vertical',
  },
  customersWizard: {
    id: 'authenticator.onboarding.activities.wizard.customers',
    defaultMessage: 'Your clients',
  },
  returnsWizard: {
    id: 'authenticator.onboarding.activities.wizard.returns',
    defaultMessage: 'Your returns',
  },
  saveContextError: {
    id: 'authenticator.onboarding.activities.saveContextError',
    defaultMessage: 'Error on saving your configuration',
  },
});
