import { OnboardingActivityTypes } from './OnboardingActivities.constant';
import { OnboardingActivitiesSubmitValues } from './OnboardingActivities.types';

const adaptOnboardingActivities = ({
  activities,
}: {
  activities: OnboardingActivitiesSubmitValues;
}): string[] => {
  const adaptedSelectedCustomers =
    activities.selectedCustomers.customers.reduce((acc: string[], customer) => {
      if (customer === OnboardingActivityTypes.BOTH_CUSTOMER) {
        return [
          ...acc,
          OnboardingActivityTypes.ENTERPRISE,
          OnboardingActivityTypes.PRIVATE,
        ];
      }

      return [...acc, customer];
    }, []);

  return activities.selectedCustomerVertical.concat(
    activities.selectedDomains,
    activities.selectedReturns.volumes,
    activities.selectedReturns.issues,
    adaptedSelectedCustomers,
  );
};

export default adaptOnboardingActivities;
