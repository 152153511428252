import { transparentize } from 'polished';
import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants';
import { rem } from '@savgroup-front-common/core/src/helpers';

export const $PageWrapper = styled.div`
  overflow: hidden;
  position: relative;
  min-height: 100vh;
`;

export const $HeaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
  height: 64px;
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid
    ${({ theme }) => transparentize(0.75, theme.colors.default)};
  margin-bottom: 1rem;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.06);
`;

export const $MenuContainer = styled.div`
  position: relative;
  align-items: center;
  height: ${rem(64)};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const $SetUpContainer = styled.div`
  padding: 128px 208px 32px 208px;

  @media ${media.maxWidth.md} {
    padding: 104px;
  }

  @media ${media.maxWidth.sm} {
    padding: 24px;
  }
`;
