import { defineMessages } from 'react-intl';

export default defineMessages({
  mainTitle: {
    id: 'authenticator.onboarding.customerVertical.mainTitle',
    defaultMessage: 'Select your customer vertical',
  },
  mainDescription: {
    id: 'authenticator.onboarding.customerVertical.mainDescription',
    defaultMessage:
      'You have the possibility to select multiple domains to qualify your activity',
  },
  next: {
    id: 'authenticator.onboarding.button.next',
    defaultMessage: 'Next',
  },
  retailer: {
    id: 'authenticator.onboarding.customerVertical.retailer',
    defaultMessage: 'Retailer',
  },
  eCommercer: {
    id: 'authenticator.onboarding.customerVertical.eCommercer',
    defaultMessage: 'E-Commerce',
  },
  repairer: {
    id: 'authenticator.onboarding.customerVertical.repairer',
    defaultMessage: 'Repairer',
  },
  manufacturer: {
    id: 'authenticator.onboarding.customerVertical.manufacturer',
    defaultMessage: 'Manufacturer',
  },
  industrial: {
    id: 'authenticator.onboarding.customerVertical.industrial',
    defaultMessage: 'Industrial',
  },
  wholesaler: {
    id: 'authenticator.onboarding.customerVertical.wholesaler',
    defaultMessage: 'Wholesaler',
  },
});
