import { NavArrowLeft, NavArrowRight } from 'iconoir-react';
import React, { useCallback, useState } from 'react';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { SUPPORTED_ICON_POSITIONS } from '@savgroup-front-common/core/src/helpers';
import { useStepOrchestratorContext } from '@savgroup-front-common/core/src/molecules/StepsOrchestrator';

import ActivitiesCardSelector from '../../ActivitiesCardSelector/ActivitiesCardSelector';

import messages from './messages';
import { useGetReturnsActivity } from './ReturnsPage.hooks';
import { $Footer, $MainTitle, $Separator } from './ReturnsPage.styles';

const ReturnsPage: React.FC = () => {
  const activities = useGetReturnsActivity();
  const [selectedReturns, setSelectedReturns] = useState<
    Record<string, string[]>
  >({ volumes: [], methods: [], issues: [] });
  const [selectedCustomers, setSelectedCustomers] = useState<
    Record<string, string[]>
  >({ customers: [] });
  const { nextStep, previousStep, valuesFromParent } =
    useStepOrchestratorContext();

  const handleSelectedCustomers = useCallback(
    (customer: string, name?: string) => {
      if (!name) {
        return;
      }
      if (selectedCustomers[name].includes(customer)) {
        setSelectedCustomers({
          ...selectedCustomers,
          [name]: selectedCustomers[name].filter((c) => c !== customer),
        });

        return;
      }
      setSelectedCustomers({ ...selectedCustomers, [name]: [customer] });
    },
    [selectedCustomers],
  );

  const handleSelectedUniqueReturns = useCallback(
    (returns: string, name?: string) => {
      if (!name) {
        return;
      }
      if (selectedReturns[name].includes(returns)) {
        setSelectedReturns({
          ...selectedReturns,
          [name]: selectedReturns[name].filter((r) => r !== returns),
        });

        return;
      }
      setSelectedReturns({ ...selectedReturns, [name]: [returns] });
    },
    [selectedReturns],
  );

  return (
    <>
      <$MainTitle>
        <SafeFormattedMessageWithoutSpread
          message={messages.returnVolumeTitle}
        />
      </$MainTitle>
      <ActivitiesCardSelector
        activities={activities.returnVolume}
        rowSize={5}
        handleSelectedActivities={handleSelectedUniqueReturns}
        selectedActivities={selectedReturns.volumes}
        name="volumes"
      />
      <$Separator />
      <$MainTitle>
        <SafeFormattedMessageWithoutSpread message={messages.customersTitle} />
      </$MainTitle>
      <ActivitiesCardSelector
        activities={activities.customers}
        rowSize={3}
        handleSelectedActivities={handleSelectedCustomers}
        selectedActivities={selectedCustomers.customers}
        name="customers"
      />
      <$Footer>
        <Button
          isNewUi
          secondary
          type={BUTTON_TYPES.BUTTON}
          icon={<NavArrowLeft width="18px" height="18px" />}
          onClick={previousStep}
        >
          <SafeFormattedMessageWithoutSpread message={messages.previous} />
        </Button>
        <Button
          primary
          isNewUi
          type={BUTTON_TYPES.BUTTON}
          onClick={() => nextStep({ selectedReturns, selectedCustomers })}
          icon={<NavArrowRight width="18px" height="18px" />}
          position={SUPPORTED_ICON_POSITIONS.RIGHT}
          disabled={
            !selectedReturns.volumes.length ||
            !selectedCustomers.customers.length
          }
          isLoading={valuesFromParent?.isLoading as boolean}
        >
          <SafeFormattedMessageWithoutSpread message={messages.next} />
        </Button>
      </$Footer>
    </>
  );
};

ReturnsPage.displayName = 'ReturnsPage';

export default ReturnsPage;
