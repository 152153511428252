import { COMMON_STEPS } from '@savgroup-front-common/constants/src';
import { Step } from '@savgroup-front-common/core/src/molecules/StepsOrchestrator';

import messages from './messages';
import CustomerVerticalPage from './steps/CustomerVerticalPage/CustomerVerticalPage';
import DomainsPage from './steps/DomainsPage/DomainsPage';
import ReturnsPage from './steps/ReturnsPage/ReturnsPage';

export enum ACTIVITIES_STEPS_NAMES {
  CUSTOMER_VERTICAL_PAGE = 'customerVerticalPage',
  DOMAINS_PAGE = 'domainsPage',
  RETURNS_PAGE = 'returnsPage',
}

const selectCustomerVerticalStep: Step<Record<string, unknown>> = {
  name: ACTIVITIES_STEPS_NAMES.CUSTOMER_VERTICAL_PAGE,
  nextStep: ACTIVITIES_STEPS_NAMES.DOMAINS_PAGE,
  Component: CustomerVerticalPage,
  message: messages.customerVerticalWizard,
};

const selectDomainsStep: Step<Record<string, unknown>> = {
  name: ACTIVITIES_STEPS_NAMES.DOMAINS_PAGE,
  nextStep: ACTIVITIES_STEPS_NAMES.RETURNS_PAGE,
  Component: DomainsPage,
  message: messages.domainsWizard,
};

const selectReturnsStep: Step<Record<string, unknown>> = {
  name: ACTIVITIES_STEPS_NAMES.RETURNS_PAGE,
  nextStep: COMMON_STEPS.SUBMIT,
  Component: ReturnsPage,
  message: messages.returnsWizard,
};

export const ACTIVITIES_STEPS = [
  selectCustomerVerticalStep,
  selectDomainsStep,
  selectReturnsStep,
];
