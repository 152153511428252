import {
  Bicycle,
  Dishwasher,
  Gym,
  Laptop,
  Puzzle,
  RefreshDouble,
  Shirt,
  Sofa,
  Stroller,
  ThreeStars,
  Tools,
  Wristwatch,
} from 'iconoir-react';
import React from 'react';

import { OnboardingActivityTypes } from '../../OnboardingActivities.constant';

import messages from './messages';

export const useGetDomainsActivity = () => {
  return [
    {
      value: OnboardingActivityTypes.HIGH_TECH,
      label: messages.highTech,
      icon: <Laptop />,
    },
    {
      value: OnboardingActivityTypes.HOME_APPLIANCES,
      label: messages.homeAppliances,
      icon: <Dishwasher />,
    },
    {
      value: OnboardingActivityTypes.HANDIWORK,
      label: messages.handiwork,
      icon: <Tools />,
    },
    {
      value: OnboardingActivityTypes.SPORT,
      label: messages.sport,
      icon: <Gym />,
    },
    {
      value: OnboardingActivityTypes.MOBILITY,
      label: messages.mobility,
      icon: <Bicycle />,
    },
    {
      value: OnboardingActivityTypes.FURNISHING,
      label: messages.furnishing,
      icon: <Sofa />,
    },
    {
      value: OnboardingActivityTypes.BEAUTY,
      label: messages.beauty,
      icon: <ThreeStars />,
    },
    {
      value: OnboardingActivityTypes.MODE,
      label: messages.mode,
      icon: <Shirt />,
    },
    {
      value: OnboardingActivityTypes.JEWELRY,
      label: messages.jewelry,
      icon: <Wristwatch />,
    },
    {
      value: OnboardingActivityTypes.CHILDCARE,
      label: messages.childcare,
      icon: <Stroller />,
    },
    {
      value: OnboardingActivityTypes.GAMES,
      label: messages.games,
      icon: <Puzzle />,
    },
    {
      value: OnboardingActivityTypes.OTHER,
      label: messages.other,
      icon: <RefreshDouble />,
    },
  ];
};
