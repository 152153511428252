import React from 'react';
import { Controller, FormProvider } from 'react-hook-form';
import { Col, Grid, Row } from 'react-styled-flexboxgrid';

import { FormGroup, Input } from '@savgroup-front-common/core/src/atoms/Form';
import { CreateClaimValues } from 'authenticator/types';
import StepLayout from 'authenticator/view/app/layout/NewDesignLayout/StepLayout/StepLayout';
import STEP_CONFIG from 'authenticator/view/app/Routes/StepConfig';

import useEmailOrderReference from './EmailOrderReference.hooks';
import messagesForm from './messages';

interface EmailOrderReferenceProps {
  values: CreateClaimValues;
}

const EmailOrderReference: React.FC<EmailOrderReferenceProps> = ({
  values,
}) => {
  const { formContext, isLoading, onSubmit } = useEmailOrderReference({
    values,
  });

  const {
    formState: { errors, isValid },
    control,
    handleSubmit,
  } = formContext;

  return (
    <FormProvider {...formContext}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StepLayout
          values={values}
          stepName={STEP_CONFIG.CUSTOMER_INVOICE_INFORMATION}
          isDisabled={!isValid}
          isLoading={isLoading}
        >
          <Grid fluid>
            <Row>
              <Col sm={12} xs={12}>
                <FormGroup>
                  <Controller
                    control={control}
                    name="orderReference"
                    render={({ field }) => {
                      return (
                        <Input
                          name={field.name}
                          value={field.value}
                          label={messagesForm.orderReferenceLabel}
                          disableBrowserAutocomplete
                          onChange={field.onChange}
                          errors={errors}
                          isRequired
                        />
                      );
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm={12} xs={12}>
                <FormGroup>
                  <Controller
                    control={control}
                    name="email"
                    render={({ field }) => {
                      return (
                        <Input
                          name={field.name}
                          value={field.value}
                          label={messagesForm.emailLabel}
                          disableBrowserAutocomplete
                          onChange={field.onChange}
                          errors={errors}
                          isRequired
                        />
                      );
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Grid>
        </StepLayout>
      </form>
    </FormProvider>
  );
};

EmailOrderReference.displayName = 'EmailOrderReference';

export default EmailOrderReference;
