import React, { FunctionComponent } from 'react';
import { ThemeProvider } from 'styled-components';

import { Loader } from '@savgroup-front-common/core/src/atoms/loader';
import {
  generateColorsNewUI,
  Theme,
} from '@savgroup-front-common/core/src/theme/Theme';

import { useSellerTheme } from '../../hooks';
import { useClientInformationContext } from '../atoms';

import { $InitialLoaderContainer } from './App.styles';

const MyAccountThemeContainer: FunctionComponent<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const { clientInformation } = useClientInformationContext();

  const { theme, isLoading } = useSellerTheme({
    sellerId: clientInformation?.sellerId || clientInformation?.tenantId,
  });

  const newUI = generateColorsNewUI(theme);

  const themeCustom = {
    ...theme,
    newUI: {
      ...(theme?.newUI ?? {}),
      defaultColors: newUI?.defaultColors,
      variationColors: {
        ...(theme?.newUI?.variationColors ?? {}),
        light3: theme && theme.colors.default,
      },
      inputBorderColors: {
        ...(theme?.newUI?.inputBorderColors ?? {}),
        default: theme?.colors.default,
      },
    },
    flexboxgrid: {
      gutterWidth: 2,
      outerMargin: 0,
    },
    components: {
      ...(theme?.components ?? {}),
      defaultLabel: {
        ...(theme?.components?.defaultLabel ?? {}),
        fontSize: '14px',
        fontWeight: '600',
        color: '#5c616b',
        lineHeight: '16px',
      },
    },
  };

  if (isLoading) {
    return (
      <$InitialLoaderContainer>
        <Loader color="#333" size="50px" />
      </$InitialLoaderContainer>
    );
  }

  return <ThemeProvider theme={Theme(themeCustom)}>{children}</ThemeProvider>;
};

export default MyAccountThemeContainer;
