import { defineMessages } from 'react-intl';

export default defineMessages({
  orderSearchError: {
    id: 'view.card.client.orderSearchError',
    defaultMessage: 'An error has occurred',
  },
  orderReferenceLabel: {
    id: 'view.card.client.orderReferenceLabel',
    defaultMessage: 'Order Reference Label',
  },
  emailLabel: {
    id: 'view.MyAccountLoginPage.email.label',
    defaultMessage: 'Email',
  },
  emailPlaceholder: {
    id: 'view.MyAccountLoginPage.email.placeholder',
    defaultMessage: 'Fill email',
  },
  emailIsRequired: {
    id: 'view.MyAccountLoginPage.schema.emailIsRequired',
    defaultMessage: 'Email is required',
  },
  orderNumberIsRequired: {
    id: 'view.MyAccountLoginPage.schema.orderNumberIsRequired',
    defaultMessage: 'Order number is required',
  },
});
