import { CUSTOMER_TYPE } from '@savgroup-front-common/types';

export enum ORDER_LOGIN_CONFIGURATION {
  STANDARD_PRICE_LOGIN = 'StandardPriceLogin',
  PHONE_NUMBER_LOGIN_ENABLE = 'PhoneNumberLoginEnable',
  ONLY_WITH_ORDERREFERENCE = 'OnlyWithOrderReference',
  EMAIL_LOGIN_ENABLE = 'EmailLoginEnable',
  HIDDEN = 'Hidden',
}

export enum SELLER_TYPE {
  RETAILER = 'Retailer',
  SUPPLIER = 'Supplier',
  REPAIRER = 'Repairer',
}
export interface ExternalProvider {
  displayName: string | null;
  authenticationScheme: string;
}

export interface ClientInformation {
  activatedSellerPartners?: {
    sellerId: string;
    sellerName: string;
    customerType?: CUSTOMER_TYPE;
  }[];
  clientId?: string;
  clientName?: string;
  enableLocalLogin?: boolean;
  enableGenericProduct?: boolean;
  externalProviders: ExternalProvider[];
  bannerUrl?: string;
  id: string;
  hosts?: string[];
  tenantId: string;
  sellerId?: string;
  sellerType?: SELLER_TYPE;
  userType?: string;
  providers: [];
  phoneNumberLoginEnable: boolean;
  orderLoginConfiguration: ORDER_LOGIN_CONFIGURATION;
  productReferenceLoginEnable: boolean;
  isWithTicketNumberLoginEnabled: boolean;
  onLogoutRedirectUrl?: string;
}
