import { NavArrowLeft, NavArrowRight } from 'iconoir-react';
import React, { useCallback, useState } from 'react';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { SUPPORTED_ICON_POSITIONS } from '@savgroup-front-common/core/src/helpers';
import { useStepOrchestratorContext } from '@savgroup-front-common/core/src/molecules/StepsOrchestrator';

import ActivitiesCardSelector from '../../ActivitiesCardSelector/ActivitiesCardSelector';

import { useGetDomainsActivity } from './DomainsPage.hooks';
import { $Footer, $MainDescription, $MainTitle } from './DomainsPage.styles';
import messages from './messages';

const DomainsPage: React.FC = () => {
  const activities = useGetDomainsActivity();
  const [selectedDomains, setSelectedDomains] = useState<string[]>([]);
  const { nextStep, previousStep } = useStepOrchestratorContext();

  const handleSelectedDomains = useCallback(
    (domains: string) => {
      if (selectedDomains.includes(domains)) {
        setSelectedDomains(
          selectedDomains.filter((domain) => domain !== domains),
        );

        return;
      }
      setSelectedDomains([...selectedDomains, domains]);
    },
    [selectedDomains],
  );

  return (
    <>
      <$MainTitle>
        <SafeFormattedMessageWithoutSpread message={messages.mainTitle} />
      </$MainTitle>
      <$MainDescription>
        <SafeFormattedMessageWithoutSpread message={messages.mainDescription} />
      </$MainDescription>
      <ActivitiesCardSelector
        activities={activities}
        rowSize={3}
        handleSelectedActivities={handleSelectedDomains}
        selectedActivities={selectedDomains}
      />
      <$Footer>
        <Button
          isNewUi
          secondary
          type={BUTTON_TYPES.BUTTON}
          icon={<NavArrowLeft width="18px" height="18px" />}
          onClick={previousStep}
        >
          <SafeFormattedMessageWithoutSpread message={messages.previous} />
        </Button>
        <Button
          primary
          isNewUi
          type={BUTTON_TYPES.BUTTON}
          onClick={() => nextStep({ selectedDomains })}
          icon={<NavArrowRight width="18px" height="18px" />}
          position={SUPPORTED_ICON_POSITIONS.RIGHT}
          disabled={!selectedDomains.length}
        >
          <SafeFormattedMessageWithoutSpread message={messages.next} />
        </Button>
      </$Footer>
    </>
  );
};

DomainsPage.displayName = 'DomainsPage';

export default DomainsPage;
