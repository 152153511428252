import React from 'react';

import { CreateClaimValues } from 'authenticator/types';
import { ORDER_LOGIN_CONFIGURATION } from 'authenticator/types/Client';

import EmailOrderReference from './EmailOrderReference/EmailOrderReference';
import OnlyWithOrderReference from './OnlyWithOrderReference/OnlyWithOrderReference';
import PhoneNumberLoginEnable from './PhoneNumberLoginEnable/PhoneNumberLoginEnable';
import StandardPriceLogin from './StandardPriceLogin/StandardPriceLogin';

interface CustomerInvoiceInformationsProps {
  values: CreateClaimValues;
}

const CustomerInvoiceInformations: React.FC<
  CustomerInvoiceInformationsProps
> = ({ values }) => {
  return (
    <>
      {values.orderLoginConfiguration ===
        ORDER_LOGIN_CONFIGURATION.ONLY_WITH_ORDERREFERENCE && (
        <OnlyWithOrderReference values={values} />
      )}
      {values.orderLoginConfiguration ===
        ORDER_LOGIN_CONFIGURATION.PHONE_NUMBER_LOGIN_ENABLE && (
        <PhoneNumberLoginEnable values={values} />
      )}
      {values.orderLoginConfiguration ===
        ORDER_LOGIN_CONFIGURATION.STANDARD_PRICE_LOGIN && (
        <StandardPriceLogin values={values} />
      )}
      {values.orderLoginConfiguration ===
        ORDER_LOGIN_CONFIGURATION.EMAIL_LOGIN_ENABLE && (
        <EmailOrderReference values={values} />
      )}
    </>
  );
};

CustomerInvoiceInformations.displayName = 'CustomerInvoiceInformations';

export default CustomerInvoiceInformations;
