import { NavArrowRight } from 'iconoir-react';
import React, { useCallback, useState } from 'react';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { SUPPORTED_ICON_POSITIONS } from '@savgroup-front-common/core/src/helpers';
import { useStepOrchestratorContext } from '@savgroup-front-common/core/src/molecules/StepsOrchestrator';

import ActivitiesCardSelector from '../../ActivitiesCardSelector/ActivitiesCardSelector';

import useCustomerVerticalPage from './CustomerVerticalPage.hooks';
import {
  $Footer,
  $MainDescription,
  $MainTitle,
} from './CustomerVerticalPage.styles';
import messages from './messages';

const CustomerVerticalPage: React.FC = () => {
  const customerVertical = useCustomerVerticalPage();
  const [selectedCustomerVertical, setSelectedCustomerVertical] = useState<
    string[]
  >([]);
  const { nextStep } = useStepOrchestratorContext();

  const handleSelectedCustomerVertical = useCallback(
    (customerVerticalValue: string) => {
      if (!customerVerticalValue) {
        return;
      }
      if (selectedCustomerVertical.includes(customerVerticalValue)) {
        setSelectedCustomerVertical(
          selectedCustomerVertical.filter(
            (customerVertical) => customerVertical !== customerVerticalValue,
          ),
        );

        return;
      }
      setSelectedCustomerVertical([customerVerticalValue]);
    },
    [selectedCustomerVertical],
  );

  return (
    <>
      <$MainTitle>
        <SafeFormattedMessageWithoutSpread message={messages.mainTitle} />
      </$MainTitle>
      <$MainDescription>
        <SafeFormattedMessageWithoutSpread message={messages.mainDescription} />
      </$MainDescription>
      <ActivitiesCardSelector
        activities={customerVertical}
        rowSize={3}
        handleSelectedActivities={handleSelectedCustomerVertical}
        selectedActivities={selectedCustomerVertical}
      />
      <$Footer>
        <Button
          primary
          isNewUi
          type={BUTTON_TYPES.BUTTON}
          onClick={() => nextStep({ selectedCustomerVertical })}
          icon={<NavArrowRight width="18px" height="18px" />}
          position={SUPPORTED_ICON_POSITIONS.RIGHT}
          disabled={!selectedCustomerVertical.length}
        >
          <SafeFormattedMessageWithoutSpread message={messages.next} />
        </Button>
      </$Footer>
    </>
  );
};

CustomerVerticalPage.displayName = 'CustomerVerticalPage';

export default CustomerVerticalPage;
