import { City, User } from 'iconoir-react';
import React from 'react';

import { OnboardingActivityTypes } from '../../OnboardingActivities.constant';

import messages from './messages';

export const useGetReturnsActivity = () => {
  return {
    returnVolume: [
      {
        value: OnboardingActivityTypes.MIN_RETURN_VOLUME,
        label: messages.minReturnVolume,
      },
      {
        value: OnboardingActivityTypes.LITTLE_RETURN_VOLUME,
        label: messages.littleReturnVolume,
      },
      {
        value: OnboardingActivityTypes.MEDIUM_RETURN_VOLUME,
        label: messages.mediumReturnVolume,
      },
      {
        value: OnboardingActivityTypes.HIGH_RETURN_VOLUME,
        label: messages.highReturnVolume,
      },
      {
        value: OnboardingActivityTypes.MAX_RETURN_VOLUME,
        label: messages.maxReturnVolume,
      },
    ],
    customers: [
      {
        value: OnboardingActivityTypes.ENTERPRISE,
        label: messages.enterprises,
        icon: <City />,
      },
      {
        value: OnboardingActivityTypes.PRIVATE,
        label: messages.private,
        icon: <User />,
      },
      {
        value: OnboardingActivityTypes.BOTH_CUSTOMER,
        label: messages.both,
        icon: (
          <div>
            <City />
            <User />
          </div>
        ),
      },
    ],
  };
};
