import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  CIVILITY,
  COUNTRY_CODES,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { RequiredPayloadFieldIsNotProvided } from '@savgroup-front-common/exceptions';
import {
  BackResponse,
  BackResponseFailure,
  CUSTOMER_TYPE,
  ReverseMoney,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.owner}sharelink/createorder/byproductreference`;

interface AddressInfo {
  addressId?: string;
  civility?: CIVILITY;
  firstname: string;
  companyName?: string;
  lastname: string;
  address: string;
  additionalAddress?: string;
  additionalInformation?: string;
  doorCode?: string;
  floor?: string;
  postalCode: string;
  city: string;
  countryCode: COUNTRY_CODES;
  phone?: string;
  isDefault?: boolean;
}

interface NewOrderProduct {
  brandName: string;
  label: string;
  modelType: string;
  sku: string;
  price: ReverseMoney;
  id?: string;
}

export interface CreateOrderAndShareLinkPayload {
  tenantId?: string;
  payload: {
    civility?: CIVILITY;
    customerLastName?: string;
    customerFirstName?: string;
    address?: AddressInfo;
    phoneNumber?: string;
    customerMail?: string;
    purchaseDateUtc?: string;
    product?: Partial<NewOrderProduct>;
    isOutOfWarranty?: boolean;
    salesChannelType?: string;
    sellerId?: string;
    salesChannelId?: string;
    customerType?: CUSTOMER_TYPE;
    preferredLanguage: string;
  };
}

async function createOrderAndShareLink({
  tenantId,
  payload,
}: CreateOrderAndShareLinkPayload): Promise<
  BackResponse<Location> | BackResponseFailure
> {
  const functionName = 'createOrderAndShareLink';

  if (!tenantId) {
    throw new RequiredPayloadFieldIsNotProvided('tenantId', `${functionName}`);
  }

  try {
    return await apiCall<BackResponse<Location>>(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: {
        tenantId,
        payload,
      },
    });
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}

export default createOrderAndShareLink;
