import styled from 'styled-components';

export const $MainTitle = styled.div`
  font-size: ${({ theme }) => theme.fonts.size.big};
  color: ${({ theme }) => theme.newUI.defaultColors.text};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  margin-bottom: 18px;
`;

export const $MainDescription = styled.div`
  font-size: ${({ theme }) => theme.fonts.size.medium};
  color: ${({ theme }) => theme.newUI.defaultColors.secondaryText};
`;

export const $Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
`;
